export const MenuItems = [ 
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },
    {
        title: 'About',
        url: '/About',
        cName: 'nav-links'
    },
    {
        title: 'Sandbox',
        url: '/Sandbox',
        cName: 'nav-links'
    },
    {
        title: 'Photography',
        url: '/Photography',
        cName: 'nav-links'
    },
    /*{
        title: 'Contact',
        url: '/Contact',
        cName: 'nav-links-mobile'
    },*/
]