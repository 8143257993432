import React from 'react'

function Photography() {
  return (
    <>
    <div>
        <h1>Photography</h1>
    </div>

    <div>
        <p>I also take the odd picture like this sunset... more to come!</p>
    </div>

    <div>
        <img src="images/p1000289.jpg" alt="Sunset" />
    </div>
    </>
  )
}

export default Photography;