import React from 'react'

function About() {
  return (
    <>
    <div>
        <h1>About</h1>
    </div>

    <div>
        <p>Been writing code since Microsoft Frontpage days! Trying my hand at modern development tools.</p>
    </div>

    <div>
        <h2>Lipsum</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at sollicitudin metus, et sodales tellus. Sed iaculis commodo justo, suscipit fringilla sem consectetur nec. Sed leo nunc, maximus nec maximus non, ultrices eu urna. Duis lacus nulla, accumsan malesuada urna ac, consectetur aliquet felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam rutrum condimentum sem quis convallis. Aenean congue cursus efficitur. Vestibulum nibh nibh, porttitor quis dictum id, sagittis ac risus. Nunc placerat, tellus vel sollicitudin accumsan, dolor quam rutrum orci, et blandit orci risus quis est. Sed euismod tellus eget dui interdum, id semper libero euismod. Duis sit amet arcu tortor. Nam at purus bibendum, fringilla dui eu, pretium mi. </p>
    </div>
    </>

  )
}

export default About;