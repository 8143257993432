import React from 'react'

function Home() {
  return (
    <>
    <div>
        <h1>Welcome</h1>
    </div>

    <div>
        <p>My small corner of the internet to test out HTML, CSS, PHP and ReactJS</p>
    </div>

    <div>
        <h2>Lipsum</h2>
        <p>Proin at urna et augue tincidunt efficitur non et turpis. Vivamus vitae scelerisque nibh, ac congue quam. Donec luctus facilisis dui ornare scelerisque. Sed tempor vehicula arcu, vel rhoncus nibh lacinia pulvinar. Vestibulum eleifend congue mi, vel volutpat orci pharetra non. Curabitur molestie massa lacus, vitae vulputate augue luctus ut. Mauris convallis erat magna, a porta ex fermentum eget. Aenean vel vehicula libero, eget commodo justo. Quisque ac leo sed tellus ornare vulputate a sed lacus. Quisque nec justo ex. Ut id vehicula eros. Pellentesque vel ipsum ac ipsum porta rutrum. In condimentum turpis et iaculis varius. Pellentesque efficitur convallis ipsum eget sagittis. </p>
    </div>
    </>
  )
}

export default Home;