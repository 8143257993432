import './App.css';
import Navbar from './components/navbar/Navbar';
import Home from './components/views/Home'
import About from './components/views/About'
import Sandbox from './components/views/Sandbox'
import Photography from './components/views/Photography'
import Error from './components/views/Error'
/*import { useState } from 'react';*/
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
      <Router>
        <div className="App">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Sandbox" element={<Sandbox />} />
          <Route path="/Photography" element={<Photography />} />
          <Route path="*" element={<Error />} />
        </Routes>
        </div>
      </Router>
  );
}

export default App;
