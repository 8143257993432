import React from 'react'

function Sandbox() {
  return (
    <>
    <div>
        <h1>Sandbox</h1>
    </div>

    <div>
        <p>Anything cool I do I'll stick here as a portfolio</p>
    </div>

    <div>
        <h2>Lipsum</h2>
        <p>Nullam lacus lectus, ornare non metus ac, consectetur mattis mi. Suspendisse aliquam mi ac justo iaculis iaculis. Interdum et malesuada fames ac ante ipsum primis in faucibus. Duis scelerisque consequat turpis aliquet vulputate. Sed tristique odio eget blandit vehicula. Etiam porttitor ultrices nisi vel facilisis. Aliquam dictum nulla vitae ipsum condimentum mattis. Suspendisse quis urna vel felis condimentum cursus. In sed bibendum ex, id vestibulum massa. Ut ac feugiat urna. Vestibulum ut sodales mauris, ac tincidunt mi. Quisque tristique mi at sem tincidunt, non egestas ipsum mattis. Phasellus accumsan, dui nec aliquam eleifend, sem tortor aliquet nunc, dapibus tristique eros metus eget velit. Vestibulum eu lorem vitae ligula lacinia faucibus in non nunc. </p>
    </div>

   </>
  )
}

export default Sandbox;